<template>
  <section class="profile">
    <div class="page-header">
      <h3 class="page-title">
        Profil
      </h3>
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item active" aria-current="page">Profil</li>
        </ol>
      </nav>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-lg-4">
                <div class="border-bottom text-center pb-4">
                  <img src="../../assets/images/faces/face12.jpg" alt="profile" class="img-lg rounded-circle mb-3"/>
                  <div class="d-block d-sm-flex justify-content-center">
                    <b-button variant="gradient-success">Modifier le mot de passe</b-button>
                  </div>
                </div>
                <div class="border-bottom py-4">
                  <p>Niveau de sécurité du compte</p>
                  <div class="progress progress-md flex-grow">
                    <div class="progress-bar bg-gradient-success" role="progressbar" aria-valuenow="75" style="width: 75%" aria-valuemin="0" aria-valuemax="100"></div>
                  </div>
                </div>
                <div class="py-4">
                  <p class="clearfix">
                    <span class="float-left">
                      Status
                    </span>
                    <span class="float-right text-muted">
                      Actif
                    </span>
                  </p>
                  <p class="clearfix">
                    <span class="float-left">
                      Téléphone
                    </span>
                    <span class="float-right text-muted">
                      006 3435 22
                    </span>
                  </p>
                  <p class="clearfix">
                    <span class="float-left">
                      Mail
                    </span>
                    <span class="float-right text-muted">
                      Jacod@testmail.com
                    </span>
                  </p>
                  <p class="clearfix">
                    <span class="float-left">
                      Pays
                    </span>
                    <span class="float-right text-muted">
                      France
                    </span>
                  </p>
                  <p class="clearfix">
                    <span class="float-left">
                      Fuseau
                    </span>
                    <span class="float-right text-muted">
                      Europe/Zurich
                    </span>
                  </p>
                </div>
                <b-button variant="gradient-primary btn-block" @click="applications()">Vos applications</b-button>
              </div>
              <div class="col-lg-8">
                <div class="d-lg-flex justify-content-between">
                  <div>
                    <h3 class="mt-3 mt-lg-0">{{myinfos.firstname}} {{myinfos.lastname}}</h3>
                    <div class="d-flex align-items-center">
                      <h5 class="mb-0 mr-2 text-muted mt-3 mt-lg-0">RAKOTOSON SATA</h5>
                    </div>
                  </div>
                  <div class="mt-3 mt-lg-0">
                    <b-button variant="outline-secondary btn-icon">
                      <i class="mdi mdi-comment-processing"></i>
                    </b-button>
                    <b-button variant="gradient-primary">Support</b-button>
                  </div>
                </div>
                <div class="mt-4 py-2 border-top border-bottom">
                  <ul class="nav profile-navbar">
                    <li class="nav-item">
                      <a class="nav-link active" href="javascript:void(0);">
                        <i class="mdi mdi-comment"></i>
                        Actualités Ailem
                      </a>
                    </li>
                    <li class="nav-item">
                      <a class="nav-link" href="javascript:void(0);">
                        <i class="mdi mdi-calendar"></i>
                        Agenda
                      </a>
                    </li>
                  </ul>
                </div>
                <div class="profile-feed">
                  <div class="d-flex align-items-start profile-feed-item">
                    <!-- <img src="./assets/images/faces/face13.jpg" alt="profile" class="img-sm rounded-circle"/> -->
                    <div class="ml-4">
                      <h6>
                        Support Ailem
                        <small class="ml-4 text-muted"><i class="mdi mdi-clock mr-1"></i>10 hours</small>
                      </h6>
                      <p>
                        Une nouvelle mise à jour a été appliquée sur la partie réservation
                      </p>
                    </div>
                  </div>
                  <div class="d-flex align-items-start profile-feed-item">
                    <!-- <img src="./assets/images/faces/face13.jpg" alt="profile" class="img-sm rounded-circle"/> -->
                    <div class="ml-4">
                      <h6>
                        Rappel abonnement
                        <small class="ml-4 text-muted"><i class="mdi mdi-clock mr-1"></i>01/05/2023</small>
                      </h6>
                      <p>
                        Votre abonnement sur Mon échappée belle sera bientôt expirée
                      </p>
                    </div>
                  </div>
                  <div class="d-flex align-items-start profile-feed-item">
                    <!-- <img src="./assets/images/faces/face13.jpg" alt="profile" class="img-sm rounded-circle"/> -->
                    <div class="ml-4">
                      <h6>
                        Bienvenue!
                        <small class="ml-4 text-muted"><i class="mdi mdi-clock mr-1"></i>02/05/2022</small>
                      </h6>
                      <p>
                        Merci de nous avoir choisi pour la numérisation de votre activité
                      </p>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
const axios = require ('axios').default
export default {
  name: 'profile',
  data() {
      return {
          myinfos:'',
          userratings:[]
      }
  },
  methods:{
    applications(){
      this.$router.push({
        name:'applications'
      })
    }
  },
  async created () {
      const response= await axios.get('myinfos')
      this.myinfos=response.data.result
      
      axios.get('userratings')
      .then(res=> {
        this.userratings= res.data.result
      })
      .catch(err=> console.log('err',err))
  }
}
</script>