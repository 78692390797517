var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"profile"},[_vm._m(0),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-4"},[_c('div',{staticClass:"border-bottom text-center pb-4"},[_c('img',{staticClass:"img-lg rounded-circle mb-3",attrs:{"src":require("../../assets/images/faces/face12.jpg"),"alt":"profile"}}),_c('div',{staticClass:"d-block d-sm-flex justify-content-center"},[_c('b-button',{attrs:{"variant":"gradient-success"}},[_vm._v("Modifier le mot de passe")])],1)]),_vm._m(1),_vm._m(2),_c('b-button',{attrs:{"variant":"gradient-primary btn-block"},on:{"click":function($event){return _vm.applications()}}},[_vm._v("Vos applications")])],1),_c('div',{staticClass:"col-lg-8"},[_c('div',{staticClass:"d-lg-flex justify-content-between"},[_c('div',[_c('h3',{staticClass:"mt-3 mt-lg-0"},[_vm._v(_vm._s(_vm.myinfos.firstname)+" "+_vm._s(_vm.myinfos.lastname))]),_vm._m(3)]),_c('div',{staticClass:"mt-3 mt-lg-0"},[_c('b-button',{attrs:{"variant":"outline-secondary btn-icon"}},[_c('i',{staticClass:"mdi mdi-comment-processing"})]),_c('b-button',{attrs:{"variant":"gradient-primary"}},[_vm._v("Support")])],1)]),_vm._m(4),_vm._m(5)])])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page-header"},[_c('h3',{staticClass:"page-title"},[_vm._v(" Profil ")]),_c('nav',{attrs:{"aria-label":"breadcrumb"}},[_c('ol',{staticClass:"breadcrumb"},[_c('li',{staticClass:"breadcrumb-item active",attrs:{"aria-current":"page"}},[_vm._v("Profil")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"border-bottom py-4"},[_c('p',[_vm._v("Niveau de sécurité du compte")]),_c('div',{staticClass:"progress progress-md flex-grow"},[_c('div',{staticClass:"progress-bar bg-gradient-success",staticStyle:{"width":"75%"},attrs:{"role":"progressbar","aria-valuenow":"75","aria-valuemin":"0","aria-valuemax":"100"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"py-4"},[_c('p',{staticClass:"clearfix"},[_c('span',{staticClass:"float-left"},[_vm._v(" Status ")]),_c('span',{staticClass:"float-right text-muted"},[_vm._v(" Actif ")])]),_c('p',{staticClass:"clearfix"},[_c('span',{staticClass:"float-left"},[_vm._v(" Téléphone ")]),_c('span',{staticClass:"float-right text-muted"},[_vm._v(" 006 3435 22 ")])]),_c('p',{staticClass:"clearfix"},[_c('span',{staticClass:"float-left"},[_vm._v(" Mail ")]),_c('span',{staticClass:"float-right text-muted"},[_vm._v(" Jacod@testmail.com ")])]),_c('p',{staticClass:"clearfix"},[_c('span',{staticClass:"float-left"},[_vm._v(" Pays ")]),_c('span',{staticClass:"float-right text-muted"},[_vm._v(" France ")])]),_c('p',{staticClass:"clearfix"},[_c('span',{staticClass:"float-left"},[_vm._v(" Fuseau ")]),_c('span',{staticClass:"float-right text-muted"},[_vm._v(" Europe/Zurich ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex align-items-center"},[_c('h5',{staticClass:"mb-0 mr-2 text-muted mt-3 mt-lg-0"},[_vm._v("RAKOTOSON SATA")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mt-4 py-2 border-top border-bottom"},[_c('ul',{staticClass:"nav profile-navbar"},[_c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link active",attrs:{"href":"javascript:void(0);"}},[_c('i',{staticClass:"mdi mdi-comment"}),_vm._v(" Actualités Ailem ")])]),_c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link",attrs:{"href":"javascript:void(0);"}},[_c('i',{staticClass:"mdi mdi-calendar"}),_vm._v(" Agenda ")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"profile-feed"},[_c('div',{staticClass:"d-flex align-items-start profile-feed-item"},[_c('div',{staticClass:"ml-4"},[_c('h6',[_vm._v(" Support Ailem "),_c('small',{staticClass:"ml-4 text-muted"},[_c('i',{staticClass:"mdi mdi-clock mr-1"}),_vm._v("10 hours")])]),_c('p',[_vm._v(" Une nouvelle mise à jour a été appliquée sur la partie réservation ")])])]),_c('div',{staticClass:"d-flex align-items-start profile-feed-item"},[_c('div',{staticClass:"ml-4"},[_c('h6',[_vm._v(" Rappel abonnement "),_c('small',{staticClass:"ml-4 text-muted"},[_c('i',{staticClass:"mdi mdi-clock mr-1"}),_vm._v("01/05/2023")])]),_c('p',[_vm._v(" Votre abonnement sur Mon échappée belle sera bientôt expirée ")])])]),_c('div',{staticClass:"d-flex align-items-start profile-feed-item"},[_c('div',{staticClass:"ml-4"},[_c('h6',[_vm._v(" Bienvenue! "),_c('small',{staticClass:"ml-4 text-muted"},[_c('i',{staticClass:"mdi mdi-clock mr-1"}),_vm._v("02/05/2022")])]),_c('p',[_vm._v(" Merci de nous avoir choisi pour la numérisation de votre activité ")])])])])
}]

export { render, staticRenderFns }